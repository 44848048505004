import React, { useEffect, useState } from "react";
import { Container, Card, ListGroup } from "react-bootstrap";
import axios from "axios";

const SubmittedForms = () => {
  const [forms, setForms] = useState([]);

  useEffect(() => {
    axios
      .get("/api/customers")
      .then((response) => {
        setForms(response.data);
      })
      .catch((error) => {
        console.error("Error fetching forms:", error);
      });
  }, []);

  return (
    <Container className="mt-5">
      <h2>Submitted Forms</h2>
      {forms.length > 0 ? (
        forms.map((form) => (
          <Card key={form._id} className="mb-3">
            <Card.Header>
              <strong>{form.name}</strong> - {form.email}
            </Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {Object.entries(form).map(([key, value]) => (
                  <ListGroup.Item key={key}>
                    <strong>{key}:</strong> {value?.toString()}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        ))
      ) : (
        <p>No forms submitted yet.</p>
      )}
    </Container>
  );
};

export default SubmittedForms;
