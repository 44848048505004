import React, { useState } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axios.post(`/api/login`, {
        email,
        password,
        username,
      });

      if (response.data.token) {
        localStorage.setItem("userToken", response.data.token);
        localStorage.setItem("username", response.data.username);
      } else {
        throw new Error("Token not found in response");
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("Login failed. Please try again.");
    }
  };

  const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "140%",
    height: "100vh",
  };
  const formContainerStyle = {
    opacity: 0.9,
    width: "100%",
    padding: "20px",
    borderRadius: "15px",
    background: "white",
    marginLeft: "500px",
  };
  const inputStyle = {
    height: "45px",
    fontSize: "1em",
    width: "100%",
  };
  return (
    <div style={wrapperStyle}>
      <Container style={formContainerStyle}>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <center>
              <h3 className="card-title text-center mb-4">Login</h3>
            </center>
          </Form.Group>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group>
            <Form.Label>UserName</Form.Label>
            <Form.Control
              type="text"
              name="username"
              placeholder="username"
              onChange={(e) => setUsername(e.target.value)}
              required
              style={inputStyle}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
              required
              style={inputStyle}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
              style={inputStyle}
            />
          </Form.Group>
          <Button type="submit" className="loginformbutton">
            Login
          </Button>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Link to="/signup">Don't have an account? Sign Up</Link>
          </div>
          {/* <div>
            <button onClick={handleGoogleLogin} className="loginformbutton">
              Sign in with Google
            </button>
          </div> */}
          <div style={{ marginTop: "10px" }}>
            <Link to="/forgotpassword">ForgotPassword</Link>
          </div>
        </Form>
      </Container>
    </div>
  );
};
export default Login;
