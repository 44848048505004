import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import useWebSocket from "react-use-websocket";
import Switch from "react-switch";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-adapter-date-fns";
import "./CellData.css"; // Import the CSS file

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  annotationPlugin
);

function CellData() {
  const [cellData, setCellData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fastDischargeMessage, setFastDischargeMessage] = useState("");
  const [currentDay, setCurrentDay] = useState("");
  const [numDataPoints, setNumDataPoints] = useState(10);
  const [relayStatus, setRelayStatus] = useState("OFF"); // Historical relay data status
  const [relay, setRelay] = useState("OFF"); // Current relay state
  const [alertLogs, setAlertLogs] = useState([]); // Alert logs state
  const [seenAlerts, setSeenAlerts] = useState(new Set()); // Set to track seen alerts

  const { sendMessage, lastJsonMessage, readyState } = useWebSocket(
    "wss://www.transcent.in/ws/main",
    {
      shouldReconnect: (closeEvent) => true, // Always attempt to reconnect
      reconnectInterval: 3000, // Reconnect every 3000 milliseconds
      onOpen: () => console.log("Connected to WebSocket"),
      onClose: () => console.log("Disconnected from WebSocket"),
      onMessage: (message) => {
        handleWebSocketMessage(message);
      },
    }
  );
  useEffect(() => {
    if (readyState === WebSocket.OPEN) {
      console.log("WebSocket is open.");
    } else if (readyState === WebSocket.CLOSED) {
      console.error("WebSocket is closed. Trying to reconnect...");
    }
  }, [readyState]);

  useEffect(() => {
    fetchData();
  }, [currentDay]);

  useEffect(() => {
    sendMessage(JSON.stringify({ type: "relaystatus" }));
    sendMessage(JSON.stringify({ type: "fetchdata" }));
  }, []);

  const fetchData = async () => {
    try {
      sendMessage(JSON.stringify({ type: "fetchdata" }));
    } catch (error) {
      console.error("Error fetching new cell data: ", error);
      setError(error.toString());
      setLoading(false);
    }
  };

  const handleWebSocketMessage = (message) => {
    const data = JSON.parse(message.data);

    if (data.message === "DATA UPDATE") {
      console.log("Handling new cell data:", data.data);
      handleNewCellData(data.data);
      setLoading(false);
    } else if (data.message === "RELAY STATUS") {
      console.log("Setting relay status:", data.data);
      setRelayStatus(data.data);
    } else if (data.message === "RELAY TOGGLE") {
      console.log("Setting relay state:", data.data.relay);
      setRelay(data.data.relay);
    } else if (data.message === "ERROR") {
      console.error("Error message received:", data.message);
      setError(data.message);
      setLoading(false);
    }
  };

  const handleNewCellData = (newData) => {
    if (!Array.isArray(newData)) {
      newData = [newData]; // Ensure newData is an array
    }

    console.log("Updating cell data...");
    setCellData((prevData) => {
      const updatedData = [...prevData, ...newData].slice(-numDataPoints);
      checkFastDischarge(updatedData);
      return updatedData;
    });
    checkVoltageAlerts(newData); // Ensure alerts are checked for new data
  };

  const checkVoltageAlerts = (latestEntry) => {
    const newSeenAlerts = new Set(seenAlerts);
    const newAlertLogs = [...alertLogs];

    const addAlert = (alert) => {
      if (!newSeenAlerts.has(alert)) {
        newAlertLogs.push(alert);
        newSeenAlerts.add(alert);
      }
    };

    const removeAlert = (alert) => {
      if (newSeenAlerts.has(alert)) {
        newSeenAlerts.delete(alert);
        const index = newAlertLogs.indexOf(alert);
        if (index > -1) {
          newAlertLogs.splice(index, 1);
        }
      }
    };

    if (latestEntry.cell1_voltage < 2.2) {
      addAlert("Cell 1 over discharge alert");
    } else {
      removeAlert("Cell 1 over discharge alert");
    }

    if (latestEntry.cell2_voltage < 2.2) {
      addAlert("Cell 2 over discharge alert");
    } else {
      removeAlert("Cell 2 over discharge alert");
    }

    if (latestEntry.cell3_voltage < 2.2) {
      addAlert("Cell 3 over discharge alert");
    } else {
      removeAlert("Cell 3 over discharge alert");
    }

    if (latestEntry.cell4_voltage < 2.2) {
      addAlert("Cell 4 over discharge alert");
    } else {
      removeAlert("Cell 4 over discharge alert");
    }

    if (latestEntry.cell1_voltage > 3.65) {
      addAlert("Cell 1 overcharge alert");
    } else {
      removeAlert("Cell 1 overcharge alert");
    }

    if (latestEntry.cell2_voltage > 3.65) {
      addAlert("Cell 2 overcharge alert");
    } else {
      removeAlert("Cell 2 overcharge alert");
    }

    if (latestEntry.cell3_voltage > 3.65) {
      addAlert("Cell 3 overcharge alert");
    } else {
      removeAlert("Cell 3 overcharge alert");
    }

    if (latestEntry.cell4_voltage > 3.65) {
      addAlert("Cell 4 overcharge alert");
    } else {
      removeAlert("Cell 4 overcharge alert");
    }

    setSeenAlerts(newSeenAlerts);
    setAlertLogs(newAlertLogs.slice(-3)); // Limit to last 3 alerts
  };

  const checkFastDischarge = (data) => {
    console.log("Checking for fast discharge...");
    const len = data.length;
    if (len < 2) return;

    const previousEntry = data[len - 2];
    const currentEntry = data[len - 1];

    const cellVoltages = [
      currentEntry.cell1_voltage,
      currentEntry.cell2_voltage,
      currentEntry.cell3_voltage,
      currentEntry.cell4_voltage,
    ];

    const prevCellVoltages = [
      previousEntry.cell1_voltage,
      previousEntry.cell2_voltage,
      previousEntry.cell3_voltage,
      previousEntry.cell4_voltage,
    ];

    for (let i = 0; i < cellVoltages.length; i++) {
      const otherCellVoltages = cellVoltages.filter((_, j) => j !== i);
      if (
        prevCellVoltages[i] > cellVoltages[i] &&
        cellVoltages[i] < Math.min(...otherCellVoltages) - 0.3
      ) {
        const alert = `Cell ${i + 1} is fast discharging`;
        setFastDischargeMessage(alert);
        if (!seenAlerts.has(alert)) {
          setAlertLogs((prevLogs) => [...prevLogs, alert].slice(-3)); // Limit to last 3 alerts
          setSeenAlerts((prevSeen) => new Set(prevSeen).add(alert));
        }
        console.log(alert);
        return;
      }
    }

    setFastDischargeMessage("");
  };

  const handleDataPointsChange = (event) => {
    const value =
      event.target.value === "" ? "" : parseInt(event.target.value, 10);
    if (value === "" || (!isNaN(value) && value >= 0)) {
      setNumDataPoints(value);
    }
  };

  const handleSubmit = () => {
    console.log("Submitting data points change...");
    fetchData();
  };

  const handleToggleChange = async (checked) => {
    const newState = checked ? "ON" : "OFF";
    try {
      sendMessage(JSON.stringify({ type: "relaytoggle", relay: newState }));
    } catch (error) {
      console.error("Error toggling relay state:", error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const labels = cellData.map((item) =>
    item.createdAt ? new Date(item.createdAt) : "N/A"
  );
  const cell1VoltageData = cellData.map((item) => item.cell1_voltage);
  const cell2VoltageData = cellData.map((item) => item.cell2_voltage);
  const cell3VoltageData = cellData.map((item) => item.cell3_voltage);
  const cell4VoltageData = cellData.map((item) => item.cell4_voltage);
  const overallCurrentData = cellData.map((item) => item.overall_current);
  const overallTemperatureData = cellData.map(
    (item) => item.overall_temperature
  );

  const voltageData = {
    labels,
    datasets: [
      {
        label: "Cell 1 Voltage (V)",
        data: cell1VoltageData,
        borderColor: "rgba(120, 130, 130, 1)",
        backgroundColor: "rgba(120, 130, 130, 0.2)",
        borderWidth: 2,
        yAxisID: "y-voltage",
      },
      {
        label: "Cell 2 Voltage (V)",
        data: cell2VoltageData,
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 2,
        yAxisID: "y-voltage",
      },
      {
        label: "Cell 3 Voltage (V)",
        data: cell3VoltageData,
        borderColor: "rgba(255, 206, 86, 1)",
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderWidth: 2,
        yAxisID: "y-voltage",
      },
      {
        label: "Cell 4 Voltage (V)",
        data: cell4VoltageData,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderWidth: 2,
        yAxisID: "y-voltage",
      },
    ],
  };

  const currentData = {
    labels,
    datasets: [
      {
        label: "Overall Current (A)",
        data: overallCurrentData,
        borderColor: "rgba(255, 159, 64, 1)",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderWidth: 2,
        yAxisID: "y-current",
      },
    ],
  };

  const temperatureData = {
    labels,
    datasets: [
      {
        label: "Overall Temperature (°C)",
        data: overallTemperatureData,
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderWidth: 2,
        yAxisID: "y-temperature",
      },
    ],
  };

  const voltageOptions = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
          tooltipFormat: "HH:mm",
          displayFormats: {
            minute: "HH:mm",
          },
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      "y-voltage": {
        title: {
          display: true,
          text: "Voltage (V)",
        },
        position: "left",
        min: 2,
        max: 4,
        ticks: {
          stepSize: 0.2,
        },
      },
    },
    plugins: {
      annotation: {
        annotations: {
          line1: {
            type: "line",
            yMin: 2.2,
            yMax: 2.2,
            borderColor: "red",
            borderWidth: 2,
            label: {
              content: "Over Discharge",
              enabled: true,
              position: "center",
            },
          },
          line2: {
            type: "line",
            yMin: 3.65,
            yMax: 3.65,
            borderColor: "red",
            borderWidth: 2,
            label: {
              content: "Over Charge",
              enabled: true,
              position: "center",
            },
          },
        },
      },
    },
  };

  const currentOptions = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
          tooltipFormat: "HH:mm",
          displayFormats: {
            minute: "HH:mm",
          },
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      "y-current": {
        title: {
          display: true,
          text: "Current (A)",
        },
        position: "left",
        min: -2,
        max: 3,
        ticks: {
          stepSize: 0.5,
          callback: function (value) {
            return value.toFixed(1); // Format tick labels as required
          },
        },
      },
    },
  };

  const temperatureOptions = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "minute",
          tooltipFormat: "HH:mm",
          displayFormats: {
            minute: "HH:mm",
          },
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      "y-temperature": {
        title: {
          display: true,
          text: "Temperature (°C)",
        },
        min: -10,
        max: 60,
        ticks: {
          stepSize: 5,
        },
      },
    },
  };

  return (
    <div className="cell-data-container">
      <div className="header">
        <h1>Cell Data</h1>
        <div className="relay-switch">
          <span>Relay Button:</span>
          <Switch
            onChange={handleToggleChange}
            checked={relay === "ON"}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#00ff00"
            offColor="#ff0000"
            height={20}
            width={48}
            handleDiameter={24}
          />
        </div>
        <div className="relay-data">
          <span className="relay-status">Relay Status:</span>
          <p>{relayStatus}</p> {/* Displaying relay state as text */}
        </div>
      </div>

      <div className="data-points-input">
        <label>
          Number of Data Points:
          <input
            type="number"
            value={numDataPoints}
            onChange={handleDataPointsChange}
          />
        </label>
        <button onClick={handleSubmit}>Submit</button>
      </div>

      {fastDischargeMessage && (
        <div className="fast-discharge-message">{fastDischargeMessage}</div>
      )}

      <div>
        <h2 className="alerts-box">Alerts</h2>
        <ul>
          {alertLogs.slice(-3).map((alert, index) => (
            <li key={index}>{alert}</li>
          ))}
        </ul>
      </div>

      <h2 className="chart-title">Voltage Data</h2>
      <div className="charts">
        <div className="chartv voltage-chart">
          <Line data={voltageData} options={voltageOptions} />
        </div>

        <div className="side-by-side-charts">
          <div className="chart">
            <h2 className="chart-title">Current Data</h2>
            <Line data={currentData} options={currentOptions} />
          </div>

          <div className="chart">
            <h2 className="chart-title">Temperature Data</h2>
            <Line data={temperatureData} options={temperatureOptions} />
          </div>
        </div>
      </div>

      <h2 className="chart-title">Cell Data Table</h2>
      <table className="styled-table">
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Battery Voltage</th>
            <th>Cell 1 Voltage</th>
            <th>Cell 2 Voltage</th>
            <th>Cell 3 Voltage</th>
            <th>Cell 4 Voltage</th>
            <th>Current</th>
            <th>Temperature</th>
          </tr>
        </thead>
        <tbody>
          {cellData
            .slice()
            .reverse()
            .map((item, index) => (
              <tr key={index}>
                <td>
                  {item.createdAt
                    ? new Date(item.createdAt).toLocaleString()
                    : "N/A"}
                </td>
                <td>{item.battery_voltage} V</td>
                <td>{item.cell1_voltage} V</td>
                <td>{item.cell2_voltage} V</td>
                <td>{item.cell3_voltage} V</td>
                <td>{item.cell4_voltage} V</td>
                <td>{item.overall_current} A</td>
                <td>{item.overall_temperature} °C</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default CellData;
